import { render, staticRenderFns } from "./VideoLabel.vue?vue&type=template&id=35918a1e&scoped=true"
import script from "./VideoLabel.vue?vue&type=script&lang=js"
export * from "./VideoLabel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35918a1e",
  null
  
)

component.options.__file = "VideoLabel.vue"
export default component.exports